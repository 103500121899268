<div class="p-3 md:p-6 rounded-lg bg-slate-700">

    <div class="flex gap-5 md:w-full w-full items-center">
        <div class="w-full md:flex items-center gap-2">
            <label class="font-bold text-sm" for="catGr">Kategori</label>
            <select type="text" id="catGr" class="input-custom-1 w-full md:mt-0 mt-2">
                <option value="all">Semua</option>
                <option value="one">Sekali</option>
                <option value="everyday">Setiap Hari</option>
            </select>
        </div>
        <div class="w-full md:flex items-center gap-2">
            <label class="font-bold text-sm" for="tipe">Tipe</label>
            <select type="text" id="tipe" class="input-custom-1 w-full md:mt-0 mt-2">
                <option value="all">Semua</option>
                <option value="sports">Olahraga</option>
                <option value="live-casino">Live Casino</option>
                <option value="slots">Slots</option>
                <option value="arcade">Arcade</option>
            </select>
        </div>
    </div>
</div>

<div class="mt-5 p-3 md:p-6 rounded-lg bg-slate-700">
    <p class="text-yellow-500 text-sm font-bold">Catatan</p>
    <div class="text-sm font-bold space-y-1 mt-2">
        <div>1. Kata Sandi harus terdiri dari 8-20 karakter.</div>
        <div>2. Kata Sandi harus mengandung huruf dan angka.</div>
        <div>3. Kata Sandi tidak boleh mengandung username.</div>
    </div>

    <div class="mt-5">
        <div class="flex items-center gap-2">
            <div class="bg-green-600 w-3 h-3 rounded-xl border border-white"></div>
            <div class="text-sm font-bold">Diperbolehkan bergabung</div>
        </div>
        <div class="flex items-center gap-2">
            <div class="bg-orange-600 w-3 h-3 rounded-xl border border-white"></div>
            <div class="text-sm font-bold">Tidak dapat menentukan kelayakan</div>
        </div>
        <div class="flex items-center gap-2">
            <div class="bg-red-600 w-3 h-3 rounded-xl border border-white"></div>
            <div class="text-sm font-bold">Tidak diperbolehkan bergabung</div>
        </div>
    </div>
</div>