import { Component, OnInit } from '@angular/core';
import sitemap from '../../../../shared/utils/sitemap';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule } from '@angular/common';
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import { switchTab, textFormatDetection } from '../../../../shared/utils/utils';

@Component({
  standalone: true,
  selector: 'app-withdraw',
  templateUrl: './withdraw.component.html',
  styleUrl: './withdraw.component.scss',
  imports: [
    RouterLink,
    RouterLinkActive,
    CommonModule,
    ButtonComponent
]
})
export class WithdrawComponent implements OnInit {
  sitemap = sitemap
  currentTabState = 'bank'
  
  constructor(private readonly router: Router) { }
  ngOnInit(): void {
      switchTab({
          tabName: 'tabpane',
          defaultTab: 'bank',
          queryParams: location.search,
          onChange: (tab) => {
              this.currentTabState = tab
          },
      })
      this.router.events.subscribe(ev => {
          if (ev instanceof NavigationEnd) {
              switchTab({
                  tabName: 'tabpane',
                  defaultTab: 'bank',
                  queryParams: location.search,
                  onChange: (tab) => {
                      this.currentTabState = tab
                  },
              })
          }
      })
      textFormatDetection('input')
  }
}
