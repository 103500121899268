import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import sitemap from '../../../../shared/utils/sitemap';
import { routerEventsOnNavigationEnd, searchParams } from '../../../../shared/utils/utils';
import { ButtonComponent } from '../../../../shared/components/button/button.component';

@Component({
  standalone: true,
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss',
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    ButtonComponent
  ]
})
export class ProfileComponent implements OnInit {
  profileRoute = sitemap.getById('profile')
  currentTabState = this.profileRoute?.queryParams[0]?.tab
  constructor(private readonly router: Router) { }
  ngOnInit(): void {
    this.currentTabState = searchParams().get('tab') || this.profileRoute?.queryParams[0]?.tab
    routerEventsOnNavigationEnd(this.router, _ => this.currentTabState = searchParams().get('tab') || this.profileRoute?.queryParams[0]?.tab)
  }
}
