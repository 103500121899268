[
    {
        "label": "Togel",
        "routerLink": "/user/lottery",
        "queryParams": {
            "tab": "result"
        },
        "classCondition": [
            "lottery",
            "landing"
        ],
        "image": "assets/images/category/others.svg"
    },
    {
        "label": "Hot Games",
        "type": "children",
        "routerLink": "/user/hot-games",
        "classCondition": [
            "hot-games"
        ],
        "image": "assets/images/category/hot-games.svg",
        "children": [
            {
                "label": "Gates Of Olympus 1000",
                "provider": {
                    "label": "Pragmatic Play",
                    "id": "pragmatic-play"
                },
                "path": "pragmatic-play/gates-of-olympus",
                "image": "https://images.ctfassets.net/3h2k9ldv19d8/25vAW3dOzCO2v6iiH2K9q3/a125f5ff8fef61c19fc2c659d69afdb9/3.Square-.png?fm=webp&w=3840&q=75"
            },
            {
                "label": "Starlight Princes 1000",
                "provider": {
                    "label": "Pragmatic Play",
                    "id": "pragmatic-play"
                },
                "path": "pragmatic-play/gates-of-olympus",
                "image": "https://files.sitestatic.net/SlotImages/pragmaticplay/200/vs20starlightx.png"
            },
            {
                "label": "Sweet Bonanza 1000",
                "provider": {
                    "label": "Pragmatic Play",
                    "id": "pragmatic-play"
                },
                "path": "pragmatic-play/gates-of-olympus",
                "image": "https://files.sitestatic.net/SlotImages/pragmaticplay/200/vs20fruitswx.png?v=2"
            },
            {
                "label": "Sweet Bonanza 1000",
                "provider": {
                    "label": "Pragmatic Play",
                    "id": "pragmatic-play"
                },
                "path": "pragmatic-play/gates-of-olympus",
                "image": "https://files.sitestatic.net/SlotImages/pragmaticplay/200/vs20fruitswx.png?v=2"
            },
            {
                "label": "Sweet Bonanza 1000",
                "provider": {
                    "label": "Pragmatic Play",
                    "id": "pragmatic-play"
                },
                "path": "pragmatic-play/gates-of-olympus",
                "image": "https://files.sitestatic.net/SlotImages/pragmaticplay/200/vs20fruitswx.png?v=2"
            },
            {
                "label": "Sweet Bonanza 1000",
                "provider": {
                    "label": "Pragmatic Play",
                    "id": "pragmatic-play"
                },
                "path": "pragmatic-play/gates-of-olympus",
                "image": "https://files.sitestatic.net/SlotImages/pragmaticplay/200/vs20fruitswx.png?v=2"
            }
        ]
    },
    {
        "label": "Slots",
        "type": "parent",
        "routerLink": "/user/slots",
        "classCondition": [
            "slots"
        ],
        "image": "assets/images/category/slots.svg",
        "children": [
            {
                "label": "Pragmatic Play",
                "routerLink": "pragmatic-play",
                "image": "https://files.sitestatic.net/GameImage/SlotsProviders/mobile/normal/PPLogo.mp4?v=0.13"
            },
            {
                "label": "Pg Soft",
                "routerLink": "pg-soft",
                "image": "https://files.sitestatic.net/GameImage/SlotsProviders/mobile/normal/PGLogo.mp4?v=0.13"
            },
            {
                "label": "Joker",
                "routerLink": "joker-gaming",
                "image": "https://files.sitestatic.net/GameImage/SlotsProviders/mobile/normal/slot_joker.jpg?v=9.1"
            },
            {
                "label": "Habanero",
                "routerLink": "habanero",
                "image": "https://files.sitestatic.net/GameImage/SlotsProviders/mobile/normal/slot_haba.jpg?v=9.2"
            },
            {
                "label": "Spade Gaming",
                "routerLink": "spadegaming",
                "image": "https://files.sitestatic.net/GameImage/SlotsProviders/mobile/normal/slot_sg.jpg?v=9"
            },
            {
                "label": "Micro Gaming",
                "routerLink": "microgaming",
                "image": "https://files.sitestatic.net/GameImage/SlotsProviders/mobile/normal/MGLogo.mp4?v=0.13"
            },
            {
                "label": "Playtech",
                "routerLink": "playtech",
                "image": "https://files.sitestatic.net/GameImage/SlotsProviders/mobile/normal/slot_pt.jpg?v=9"
            },
            {
                "label": "Advant Play",
                "routerLink": "advantplay",
                "image": "https://files.sitestatic.net/GameImage/SlotsProviders/mobile/normal/advantplay_slot.jpg"
            },
            {
                "label": "Next Spin",
                "routerLink": "nextspin",
                "image": "https://files.sitestatic.net/GameImage/SlotsProviders/mobile/normal/nextspin.png"
            }
        ]
    },
    {
        "label": "Live Casino",
        "type": "parent",
        "routerLink": "/user/live-casino",
        "classCondition": [
            "live-casino"
        ],
        "image": "assets/images/category/casino.svg"
    },
    {
        "label": "Olahraga",
        "type": "parent",
        "routerLink": "/user/sports",
        "classCondition": [
            "sports"
        ],
        "image": "assets/images/category/sports.svg"
    },
    {
        "label": "Crash Game",
        "type": "parent",
        "routerLink": "/user/crash-game",
        "classCondition": [
            "crash-game"
        ],
        "image": "assets/images/category/crash-game.svg"
    },
    {
        "label": "Arcade",
        "type": "parent",
        "routerLink": "/user/arcade",
        "classCondition": [
            "arcade"
        ],
        "image": "assets/images/category/arcade.svg"
    },
    {
        "label": "Poker",
        "type": "parent",
        "routerLink": "/user/poker",
        "classCondition": [
            "poker"
        ],
        "image": "assets/images/category/poker.svg"
    },
    {
        "label": "E-Sports",
        "type": "parent",
        "routerLink": "/user/e-sports",
        "classCondition": [
            "e-sports"
        ],
        "image": "assets/images/category/e-sports.svg"
    },
    {
        "label": "Sambung Ayam",
        "type": "parent",
        "routerLink": "/user/cockfight",
        "classCondition": [
            "cockfight"
        ],
        "image": "assets/images/category/cockfight.svg"
    }
]