<div class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto mt-5 bg-slate-800 rounded-lg">
    <div class="flex justify-between border-b border-slate-600">
        <div [routerLink]="sitemap.getById('deposit')?.routerLink"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2">
            Deposit
        </div>
        <div [routerLink]="sitemap.getById('withdraw')?.routerLink"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2">
            Penarikan
        </div>
        <div [routerLink]="sitemap.getById('banking')?.routerLink"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2 bg-purple-950">
            Banking Account
        </div>
    </div>

    <div class="p-3 md:p-8 bg-slate-800">

        <div class="grid md:grid-cols-3 gap-3">
            <div class="card-holder">
                <h1 class="text-2xl font-extrabold">BCA</h1>
                <h4 class="font-bold">Dimas Satya</h4>
                <h4 class="text-card-number">*****7856</h4>
                <button class="bg-yellow-500 px-3 p-1 rounded-lg ring-2 ring-yellow-700">Salin</button>
            </div>
            <div class="card-holder">
                <h1 class="text-2xl font-extrabold">BCA</h1>
                <h4 class="font-bold">Dimas Satya</h4>
                <h4 class="text-card-number">*****7856</h4>
                <button class="bg-yellow-500 px-3 p-1 rounded-lg ring-2 ring-yellow-700">Salin</button>
            </div>
            <div class="card-holder">
                <h1 class="text-2xl font-extrabold">BCA</h1>
                <h4 class="font-bold">Dimas Satya</h4>
                <h4 class="text-card-number">*****7856</h4>
                <button class="bg-yellow-500 px-3 p-1 rounded-lg ring-2 ring-yellow-700">Salin</button>
            </div>
            <div class="card-holder">
                <h1 class="text-2xl font-extrabold">BCA</h1>
                <h4 class="font-bold">Dimas Satya</h4>
                <h4 class="text-card-number">*****7856</h4>
                <button class="bg-yellow-500 px-3 p-1 rounded-lg ring-2 ring-yellow-700">Salin</button>
            </div>
        </div>
        <div class="border-b border-slate-700 mt-4"></div>

        <div class="mt-9">
            <h2 class="border-l-4 border-purple-600 pl-3 uppercase font-bold">Tambahkan Akun</h2>

            <div class="mt-6 space-y-4 px-4">
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Tipe Pembayaran</p>
                    <select type="tel" class="input-custom-1 w-[65%] bg-slate-900">
                        <option value="bank">Bank</option>
                        <option value="ewallet">Ewallet</option>
                    </select>
                </div>
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Akun</p>
                    <select type="tel" class="input-custom-1 w-[65%] bg-slate-900">
                        <option value="BCA">Bank BCA</option>
                        <option value="BRI">Bank BRI</option>
                        <option value="BNI">Bank BNI</option>
                        <option value="BNC">Bank BNC</option>
                    </select>
                </div>
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Nomor Rekening</p>
                    <input type="tel" class="input-custom-1 w-[65%] bg-slate-900" placeholder="Nomor Rekening Anda">
                </div>
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Nama Lengkap</p>
                    <input type="tel" class="input-custom-1 w-[65%] bg-slate-900" placeholder="Nama Lengkap Anda"
                        value="Dimas Satya">
                </div>

            </div>

            <div class="flex justify-center mt-5">
                <app-button class="w-52">Tambah Akun</app-button>
            </div>
        </div>

    </div>

</div>