import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import sitemap from '../../../../shared/utils/sitemap';
import { switchTab } from '../../../../shared/utils/utils';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { CommissionComponent } from "./commission/commission.component";
import { CashbackComponent } from "./cashback/cashback.component";
import { CombinePromoComponent } from "./combine-promo/combine-promo.component";

@Component({
  standalone: true,
  selector: 'app-bonus',
  templateUrl: './bonus.component.html',
  styleUrl: './bonus.component.scss',
  imports: [
    CommonModule,
    RouterLink,
    RouterLinkActive,
    ButtonComponent,
    CommissionComponent,
    CashbackComponent,
    CombinePromoComponent
]
})
export class BonusComponent {
  sitemap = sitemap
  currentTabState = 'index'

  constructor(private readonly router: Router) { }
  ngOnInit(): void {
    switchTab({
      tabName: 'tabpane',
      defaultTab: 'index',
      queryParams: location.search,
      onChange: (tab) => {
        this.currentTabState = tab
      },
    })
    this.router.events.subscribe(ev => {
      if (ev instanceof NavigationEnd) {
        switchTab({
          tabName: 'tabpane',
          defaultTab: 'index',
          queryParams: location.search,
          onChange: (tab) => {
            this.currentTabState = tab
          },
        })
      }
    })
  }
}
