import { CommonModule } from '@angular/common';
import { Component, ContentChild, HostBinding, Input, TemplateRef, ViewChild } from '@angular/core';

import { trigger, state, style, transition, animate } from '@angular/animations';
import { MatIcon } from '@angular/material/icon';

@Component({
    standalone: true,
    selector: 'accordion-item',
    templateUrl: './accordion-item.component.html',
    styleUrls: ['./accordion-item.component.scss'],
    imports: [CommonModule, MatIcon],
    animations: [
        trigger('openClose', [
            state('open', style({
                height: '*',
                opacity: 1,
                padding: '16px'
            })),
            state('closed', style({
                height: '0',
                opacity: 0,
                padding: '0 16px'
            })),
            transition('open <=> closed', [
                animate('0.3s ease-in-out')
            ])
        ])
    ]
})
export class AccordionItemComponent {
    @HostBinding('class.open') isOpen = false;
    @ContentChild('header', { static: false }) headerTemplate!: TemplateRef<any>;
    @ContentChild('content', { static: false }) contentTemplate!: TemplateRef<any>;

    toggle(): void {
        this.isOpen = !this.isOpen;
    }
}
