<div class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto mt-5 bg-slate-800 rounded-lg">
    <div class="flex justify-between border-b border-slate-600">
        <div [routerLink]="sitemap.getById('bonus')?.routerLink" [queryParams]="{tab: 'index'}"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            [ngClass]="currentTabState === 'index' ? 'bg-purple-950' : ''">
            Bonus
        </div>
        <div [routerLink]="sitemap.getById('bonus')?.routerLink" [queryParams]="{tab: 'commission'}"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Komisi
        </div>
        <div [routerLink]="sitemap.getById('bonus')?.routerLink" [queryParams]="{tab: 'cashback'}"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Cashback
        </div>
        <div [routerLink]="sitemap.getById('bonus')?.routerLink" [queryParams]="{tab: 'combine-promo'}"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Promo Gabungan
        </div>
    </div>

    <div class="p-3 md:p-8">
        
        <div class="block mx-auto mt-5" data-pane="tabpane" id="index">
            <div class="p-3 md:p-6 rounded-lg bg-slate-700">

                <div class="flex gap-5 md:w-full w-full items-center">
                    <div class="w-full md:flex items-center gap-2">
                        <label class="font-bold text-sm" for="catGr">Kategori</label>
                        <select type="text" id="catGr" class="input-custom-1 w-full md:mt-0 mt-2">
                            <option value="all">Semua</option>
                            <option value="one">Sekali</option>
                            <option value="everyday">Setiap Hari</option>
                        </select>
                    </div>
                    <div class="w-full md:flex items-center gap-2">
                        <label class="font-bold text-sm" for="tipe">Tipe</label>
                        <select type="text" id="tipe" class="input-custom-1 w-full md:mt-0 mt-2">
                            <option value="all">Semua</option>
                            <option value="sports">Olahraga</option>
                            <option value="live-casino">Live Casino</option>
                            <option value="slots">Slots</option>
                            <option value="arcade">Arcade</option>
                        </select>
                    </div>
                </div>
            </div>

            <div class="mt-5 p-3 md:p-6 rounded-lg bg-slate-700">
                <p class="text-yellow-500 text-sm font-bold">Catatan</p>
                <div class="text-sm font-bold space-y-1 mt-2">
                    <div>1. Kata Sandi harus terdiri dari 8-20 karakter.</div>
                    <div>2. Kata Sandi harus mengandung huruf dan angka.</div>
                    <div>3. Kata Sandi tidak boleh mengandung username.</div>
                </div>

                <div class="mt-5">
                    <div class="flex items-center gap-2">
                        <div class="bg-green-600 w-3 h-3 rounded-xl border border-white"></div>
                        <div class="text-sm font-bold">Diperbolehkan bergabung</div>
                    </div>
                    <div class="flex items-center gap-2">
                        <div class="bg-orange-600 w-3 h-3 rounded-xl border border-white"></div>
                        <div class="text-sm font-bold">Tidak dapat menentukan kelayakan</div>
                    </div>
                    <div class="flex items-center gap-2">
                        <div class="bg-red-600 w-3 h-3 rounded-xl border border-white"></div>
                        <div class="text-sm font-bold">Tidak diperbolehkan bergabung</div>
                    </div>
                </div>
            </div>

            

            <div class="bg-gray-800 rounded-lg mt-5">
                <div class="">
                    <div class="flex justify-end items-center border-b-2 border-yellow-500">
                        <div class="text-sm bg-yellow-500 font-bold py-2 px-6 rounded-tl-3xl rounded-tr-sm">
                            SETIAP HARI
                        </div>
                    </div>
                </div>
                <div class="bg-slate-700 p-6 rounded-b-lg">
                    <h1 class="text-center text-white font-bold mb-3">
                        BONUS DEPOSIT HARIAN 10% SLOT GAME
                    </h1>
                    <div class="bg-slate-900 p-3 rounded-lg">
                        <div class="flex justify-center mb-4 mt-3">
                            <img alt="Treasure chest icon" class="w-24 h-24" height="100"
                                src="https://storage.googleapis.com/a1aa/image/HfAmfk3Jyzt7lkEwVWeqRIBDYFWtXsJT0fFv388QBnB5JTCPB.jpg"
                                width="100" />
                        </div>
                        <div class="bg-slate-700 h-2 rounded-full mb-4 relative">
                            <div class="bg-slate-700 h-2 rounded-full absolute w-full">
                            </div>
                            <div class="bg-orange-500 h-2 rounded-full absolute" style="width: 0%;">
                            </div>
                        </div>
                        <div class="flex justify-between items-center text-white">
                            <span>
                                Perkembangan:
                                <span class="font-bold">
                                    0.0%
                                </span>
                            </span>
                            <span class="text-orange-500">
                                <i class="fas fa-circle">
                                </i>
                            </span>
                        </div>

                    </div>
                    <div class="flex justify-end mt-4">
                        <button class="bg-slate-800 text-white py-2 px-4 rounded">
                            Info
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="block mx-auto mt-5" data-pane="tabpane" id="commission">
            <app-commission />
        </div>

        <div class="block mx-auto mt-5" data-pane="tabpane" id="cashback">
            <app-cashback />
        </div>

        <div class="block mx-auto mt-5" data-pane="tabpane" id="combine-promo">
            <app-combine-promo />
        </div>

    </div>
</div>