import { NavigationEnd, Router } from "@angular/router"
import { filter, Observer } from "rxjs"

export const routerEventsOnNavigationEnd = (router: Router, observerOrNext: Partial<Observer<NavigationEnd>> | ((value: NavigationEnd) => void) | undefined) => {
    return router.events
        .pipe(filter(ev => ev instanceof NavigationEnd))
        .subscribe(observerOrNext)
}

export const searchParams = () => {
    return new URLSearchParams(location.search)
}

export const switchTab = (props: { queryParams: any, defaultTab: string, tabName?: string, onChange: (value: string) => void }) => {
    const tab = new URLSearchParams(props.queryParams).get('tab')
    if (tab) {
        props.onChange(tab)
        const docsPane = document.querySelectorAll(`[data-pane="${props.tabName || 'tabpane'}"]`)
        docsPane.forEach(d => d.classList.replace('block', 'hidden'))
        const docEl = document.getElementById(tab)!
        docEl.classList.replace('hidden', 'block')
    } else {
        props.onChange(props.defaultTab)
        const docsPane = document.querySelectorAll(`[data-pane="${props.tabName || 'tabpane'}"]`)
        docsPane.forEach(d => d.classList.replace('block', 'hidden'))
        const docEl = document.getElementById(props.defaultTab)!
        docEl.classList.replace('hidden', 'block')
    }
}


export const textFormatDetection = (target?: HTMLElement | 'input', inject = false) => {
    const intl = new Intl.NumberFormat('id-ID'); // Perbaikan format bahasa

    if (target && target instanceof HTMLElement) {
        // Jika target adalah HTMLElement
        const text = target.textContent?.replace(/[^0-9]/g, '');
        const number = parseInt(text || '0', 10); // Tambahkan radix 10 untuk parseInt
        const formattedNumber = intl.format(number);

        if (inject) {
            target.textContent = formattedNumber;
        }
        return formattedNumber;
    } else {
        const docs = document.querySelectorAll('[text-format="currency"]');

        if (target === 'input') {
            // Penanganan khusus untuk elemen input
            docs.forEach(element => {
                const el = element as HTMLInputElement;
                el.oninput = () => {
                    const originalText = el.value.replace(/[^0-9]/g, '');
                    const number = parseInt(originalText || '0', 10);
                    el.value = intl.format(number);
                };
            });
        } else {
            // Penanganan elemen non-input
            docs.forEach(element => {
                const originalText = element.textContent?.replace(/[^0-9]/g, '');
                element.textContent = intl.format(parseInt(originalText || '0', 10));
            });
        }
        return;
    }
};
