import { Component, OnDestroy, OnInit, signal } from '@angular/core';
import { NavbarComponent } from '../../../../shared/components/navbar/navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { RouterLink, Router, NavigationEnd, RouterLinkActive } from '@angular/router';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule as PrimeButton } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { TabGamesComponent } from '../../../../shared/components/tab-games/tab-games.component';
import { Subscription } from 'rxjs';
import { MatExpansionModule } from '@angular/material/expansion';
import { ButtonComponent } from '../../../../shared/components/button/button.component';
import { TogelNumberComponent } from "../../../../shared/components/togel-number/togel-number.component";
import { MatButtonModule } from '@angular/material/button';
import { MyAlert } from '../../../../shared/utils/app.alert';
import { AccordionModule } from 'primeng/accordion';
import { AvatarModule } from 'primeng/avatar';
import { BadgeModule } from 'primeng/badge';
import { AccordionItemComponent } from '../../../../shared/components/accordion/accordion-item.component';
import { AccordionContainerComponent } from '../../../../shared/components/accordion/accordion-container.component';
import { MatSlideToggle, MatSlideToggleChange } from '@angular/material/slide-toggle';

@Component({
    standalone: true,
    selector: 'app-togel-page',
    templateUrl: './togel-page.component.html',
    styleUrl: './togel-page.component.scss',
    imports: [
        NavbarComponent,
        CommonModule,
        RouterLink,
        RouterLinkActive,
        CarouselModule,
        PrimeButton,
        TagModule,
        MatIcon,
        MatCardModule,
        TabGamesComponent,
        MatExpansionModule,
        ButtonComponent,
        TogelNumberComponent,
        MatButtonModule,
        AccordionModule,
        AvatarModule,
        BadgeModule,
        AccordionItemComponent,
        AccordionContainerComponent,
        MatSlideToggle
    ]
})
export class TogelPageComponent implements OnInit, OnDestroy {
    private routerSubscription!: Subscription;
    readonly expansionPanelOpenState = signal(false);
    currentTabState = 'result'
    alert = MyAlert
    togelGrid = true
    constructor(private router: Router) { }

    ngOnInit(): void {
        this.switchTab(location.search)
        this.routerSubscription = this.router.events.subscribe(ev => {
            if (ev instanceof NavigationEnd) {
                this.switchTab(location.search)
            }
        })
        this.togelGrid = localStorage.getItem('display_result_togel_grid') === 'true'
    }

    ngOnDestroy(): void {
        this.routerSubscription.unsubscribe();
    }

    switchTab(query: string) {
        const tab = new URLSearchParams(query).get('tab')
        if (tab) {
            this.currentTabState = tab
            const docsPane = document.querySelectorAll('[data-pane="tabpane"]')
            docsPane.forEach(d => d.classList.replace('block', 'hidden'))
            const docEl = document.getElementById(tab)!
            docEl.classList.replace('hidden', 'block')
        } else {
            const docEl = document.getElementById(this.currentTabState)!
            docEl.classList.replace('hidden', 'block')
        }
    }

    switchUiTogel(event: MatSlideToggleChange) {
        this.togelGrid = event.checked
        localStorage.setItem('display_result_togel_grid', event.checked ? 'true' : 'false')
    }

}
