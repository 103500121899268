<div class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto mt-5 bg-slate-800 rounded-lg">
    <div class="flex justify-between border-b border-slate-600">
        <div [routerLink]="sitemap.getById('deposit')?.routerLink"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2">
            Deposit
        </div>
        <div [routerLink]="sitemap.getById('withdraw')?.routerLink"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2 bg-purple-950">
            Penarikan
        </div>
        <div [routerLink]="sitemap.getById('banking')?.routerLink"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2">
            Banking Account
        </div>
    </div>

    <div class="p-3 md:p-8 bg-slate-800">

        <div>   
            <h5 class="text-sm border-l-4 border-purple-600 pl-3 uppercase font-bold">Metode Pembayaran</h5>
            <div class="flex gap-3 mt-5">
                <div class="card-select-payment" [routerLink]="sitemap.getById('withdraw')?.routerLink"
                    [queryParams]="{tab: 'bank'}" [ngClass]="currentTabState === 'bank' ? 'active' : ''">
                    <img width="35"
                        src="https://d1bnhxh1olb98c.cloudfront.net/Images/payment-types/BANK.svg?v=20240417-1" alt="">
                    <p class="text-xs md:text-sm text-dark font-bold mt-0 md:mt-2 mb-0">Bank</p>
                </div>
                <div class="card-select-payment" [routerLink]="sitemap.getById('withdraw')?.routerLink"
                    [queryParams]="{tab: 'emoney'}" routerLinkActive="active">
                    <img width="35"
                        src="https://dsuown9evwz4y.cloudfront.net/Images/payment-types/EMONEY.svg?v=20241103-1" alt="">
                    <p class="text-xs md:text-sm text-dark font-bold mt-0 md:mt-2 mb-0">E-Money</p>
                </div>
            </div>
        </div>

        <div class="block mx-auto mt-5" data-pane="tabpane" id="bank">
            <div>
                <p>Jumlah <span class="!text-red-700">*</span></p>
                <div class="relative w-full">
                    <input type="tel" name="search-text" class="input-custom-1 w-full border border-slate-700"
                        placeholder="Masukan jumlah penarikan" text-format="currency">
                    <span class="absolute right-3 top-3">IDR</span>
                </div>
            </div>

            <div class="mt-5">
                <p>Pilih Bank Anda <span class="!text-red-700">*</span></p>
                <div class="relative w-full">
                    <select type="tel" name="search-text" class="input-custom-1 w-full border border-slate-700"
                        placeholder="Masukan nominal deposit">
                        <option value="BCA | 123456789">BCA | 1232456789</option>
                    </select>
                </div>
            </div>
            <div class="flex justify-center mt-5">
                <app-button class="w-52">Kirim</app-button>
            </div>
        </div>
        <div class="hidden mx-auto mt-5" data-pane="tabpane" id="emoney">
            TAB EMONEY
        </div>


    </div>

</div>