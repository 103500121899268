import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { LoadingIndicatorComponent } from '../loading-indicator/loading-indicator.component';

export type IButtonVariant = 'success' | 'danger' | 'warning' | 'info' | 'dark' | 'outline' | 'light'

@Component({
    standalone: true,
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrl: './button.component.scss',
    imports:[
        CommonModule,
        LoadingIndicatorComponent,
    ]
})
export class ButtonComponent {
    @Input() disabled = false
    @Input() variant: IButtonVariant = 'success'
    @Input() isLoading = false
    @Input() class = ""

    loadingtext = ''

    setVariant(v: IButtonVariant) {
        this.variant = v
    }
    setDisable(v: boolean = false) {
        this.disabled = v
    }
    setLoading(v: boolean = false, withText?: string, disable?: boolean) {
        this.isLoading = v
        this.loadingtext = withText!
        this.setDisable(disable)
    }
}
