import { Component, HostListener } from '@angular/core';
import { UserPagesService } from '../../service/user-pages.service';
import { NavbarComponent } from '../../../shared/components/navbar/navbar/navbar.component';
import { CommonModule } from '@angular/common';
import { RouterLink } from '@angular/router';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule as PrimeButton } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import BannerData from '../../../../data/banner.json'
import { TabGamesComponent } from "../../../shared/components/tab-games/tab-games.component";
import { TogelPageComponent } from "../dynamic-pages/togel-page/togel-page.component";


@Component({
    standalone: true,
    selector: 'app-user-dashboard',
    templateUrl: './user-dashboard.component.html',
    styleUrl: './user-dashboard.component.scss',
    imports: [
    NavbarComponent,
    CommonModule,
    RouterLink,
    CarouselModule,
    PrimeButton,
    TagModule,
    MatIcon,
    MatCardModule,
    TabGamesComponent,
    TogelPageComponent
]
})
export class UserDashboardComponent {

    responsiveOptions: any[] | undefined;
    banners = BannerData
    constructor(private readonly userService: UserPagesService) {

    }

    ngOnInit(): void {
        this.responsiveOptions = [
            {
                breakpoint: '1199px',
                numVisible: 1,
                numScroll: 1
            },
            {
                breakpoint: '991px',
                numVisible: 2,
                numScroll: 1
            },
            {
                breakpoint: '767px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        navigator.userAgent
    }
}
