import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import sitemap from '../../../../shared/utils/sitemap';
import { switchTab, textFormatDetection } from '../../../../shared/utils/utils';
import { MatIcon } from '@angular/material/icon';
import { ButtonComponent } from "../../../../shared/components/button/button.component";
import BankingDeposit from '../../../../../data/banking-deposit.json'
@Component({
    standalone: true,
    selector: 'app-deposit',
    templateUrl: './deposit.component.html',
    styleUrl: './deposit.component.scss',
    imports: [
    RouterLink,
    CommonModule,
    RouterLinkActive,
    MatIcon,
    ButtonComponent
]
})
export class DepositComponent implements OnInit {
    sitemap = sitemap
    // private routerSubscription!: Subscription;
    BankingDeposit = BankingDeposit
    currentTabState = 'qris'

    constructor(private readonly router: Router) { }
    ngOnInit(): void {
        switchTab({
            tabName: 'tabpane',
            defaultTab: 'qris',
            queryParams: location.search,
            onChange: (tab) => {
                this.currentTabState = tab
            },
        })
        this.router.events.subscribe(ev => {
            if (ev instanceof NavigationEnd) {
                switchTab({
                    tabName: 'tabpane',
                    defaultTab: 'qris',
                    queryParams: location.search,
                    onChange: (tab) => {
                        this.currentTabState = tab
                    },
                })
            }
        })
        textFormatDetection('input')
    }

}
