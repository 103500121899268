import { Component } from '@angular/core';
import { NavbarComponent } from '../shared/components/navbar/navbar/navbar.component';
import { TabGamesComponent } from '../shared/components/tab-games/tab-games.component';
import { NavigationEnd, Router, RouterLink, RouterOutlet } from '@angular/router';
import { CarouselModule } from 'primeng/carousel';
import { ButtonModule as PrimeButton } from 'primeng/button';
import BannerData from '../../data/banner.json'
import { UserPagesService } from './service/user-pages.service';
import { FooterComponent } from "../shared/components/footer/footer.component";
import sitemap from '../shared/utils/sitemap';
import { filter } from 'rxjs';
import { LivechatComponent } from "../shared/components/livechat/livechat.component";

@Component({
    standalone: true,
    selector: 'app-user-pages',
    templateUrl: './user-pages.component.html',
    styleUrl: './user-pages.component.scss',
    imports: [
        NavbarComponent,
        TabGamesComponent,
        RouterOutlet,
        CarouselModule,
        PrimeButton,
        RouterLink,
        FooterComponent,
        LivechatComponent,
    ]
})
export class UserPagesComponent {

    responsiveOptions: any[] | undefined;
    banners = BannerData
    hideComponent = false
    constructor(
        private readonly userService: UserPagesService,
        private readonly router: Router
    ) {

    }

    ngOnInit(): void {
        this.responsiveOption()
        const pathsToHide = [
            sitemap.getById('profile')?.routerLink!,
            sitemap.getById('deposit')?.routerLink!,
            sitemap.getById('withdraw')?.routerLink!,
            sitemap.getById('banking')?.routerLink!,
            sitemap.getById('bonus')?.routerLink!,
            sitemap.getById('referral')?.routerLink!,
        ]
        this.hideComponent = pathsToHide.some(path => new RegExp(`^${path}$`).test(location.pathname));
        this.router.events
            .pipe(filter(ev => ev instanceof NavigationEnd))
            .subscribe(() => {
                this.hideComponent = pathsToHide.some(path => new RegExp(`^${path}$`).test(location.pathname));
            })
    }

    responsiveOption() {
        this.responsiveOptions = [
            {
                breakpoint: '1199px',
                numVisible: 1,
                numScroll: 1
            },
            {
                breakpoint: '991px',
                numVisible: 2,
                numScroll: 1
            },
            {
                breakpoint: '767px',
                numVisible: 1,
                numScroll: 1
            }
        ];
    }
}
