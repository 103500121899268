<div class="accordion-item">
    <div class="accordion-header flex items-center border-slate-700 border-b"
        (click)="toggle()">
        <ng-container *ngIf="headerTemplate">
            <ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
        </ng-container>
        <span class="icon text-slate-500">
            <mat-icon *ngIf="!isOpen" class="material-symbols-outlined mb-0 text-[17px]">chevron_forward</mat-icon>
            <mat-icon *ngIf="isOpen" class="material-symbols-outlined mb-0 text-[17px]">stat_minus_1</mat-icon>
            <!-- {{ isOpen ? '-' : '+' }} -->
        </span>
    </div>

    <div class="accordion-content border-b border-slate-700 shadow-sm rounded-lg w-full relative"
        [@openClose]="isOpen ? 'open' : 'closed'">
        <ng-container *ngIf="contentTemplate">
            <ng-container *ngTemplateOutlet="contentTemplate"></ng-container>
        </ng-container>
    </div>
</div>