<!-- Start Navbar -->
<nav id="topnav" class="defaultscroll is-sticky" [ngClass]="scrolled ? 'nav-sticky' : ''">
    <div class="container">
        <!-- Logo container-->
        <ng-container *ngIf="navLight; else elseTemplate">
            <a class="logo ps-0" [routerLink]="'/'">
                <img [src]="'assets/images/logo-icon-28.png'" class="inline-block sm:hidden" alt="">
                <div class="sm:block hidden">
                    <span class="inline-block dark:hidden">
                        <img [src]="'assets/images/logo-dark.png'" class="l-dark h-7" alt="">
                        <img [src]="'assets/images/logo-light.png'" class="l-light h-7" alt="">
                    </span>
                    <img [src]="'assets/images/logo-white.png'" height="24" class="hidden dark:inline-block" alt="">
                </div>
            </a>
        </ng-container>
        <ng-template #elseTemplate>
            <a class="logo ps-0" [routerLink]="'/'">
                <img [src]="'assets/images/logo-icon-28.png'" class="inline-block sm:hidden" alt="">
                <div class="sm:block hidden">
                    <img [src]="'assets/images/logo-dark.png'" class="inline-block dark:hidden h-7" alt="">
                    <img [src]="'assets/images/logo-white.png'" class="hidden dark:inline-block h-7" alt="">
                </div>
            </a>
        </ng-template>

        <!-- End Logo container-->

        <div class="menu-extras">
            <div class="menu-item">
                <!-- Mobile menu toggle-->
                <a class="navbar-toggle" [ngClass]="open ? 'open' : ''" id="isToggle" (click)="toggleMenu($event)">
                    <div class="lines">
                        <span></span>
                        <span></span>
                        <span></span>
                    </div>
                </a>
                <!-- End mobile menu toggle-->
            </div>
        </div>

        <!--Login button Start-->
        <ul class="buy-button list-none mb-0">

            <li class="dropdown inline-block relative ps-1" (clickOutside)="onClickedOutside($event)">
                <button (click)="setUser = !setUser"
                    class="dropdown-toggle btn btn-icon rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white inline-flex"
                    type="button">
                    <img [src]="'assets/images/avatar/df.png'" class="rounded-full" alt="">
                </button>
                <!-- Dropdown menu -->
                <div *ngIf="setUser"
                    class="dropdown-menu absolute end-0 m-0 mt-4 z-10 w-48 rounded-md overflow-hidden bg-white dark:bg-slate-900 shadow dark:shadow-gray-800">
                    <div class="relative">
                        <div class="py-8 bg-gradient-to-tr from-violet-600 to-red-600"></div>
                        <div class="absolute px-4 -bottom-7 start-0">
                            <div class="flex items-end">
                                <img src="assets/images/avatar/df.png"
                                    class="rounded-full w-10 h-w-10 shadow dark:shadow-gray-700" alt="">

                                <span class="font-semibold text-[15px] ms-1">Jenny Jimenez</span>
                            </div>
                        </div>
                    </div>

                    <div class="mt-10 px-4">
                        <h5 class="text-[15px]">Balance: <span class="text-violet-600 font-semibold">IDR 0.00</span>
                        </h5>
                    </div>

                    <ul class="py-2 text-start">
                        <li *ngFor="let site of sitemap.excludeById(['home', 'about'])">
                            <a [routerLink]="site.routerLink"
                                class="block text-[14px] font-semibold py-1.5 px-4 hover:text-violet-600">{{site.label}}</a>
                        </li>

                        <li class="border-t border-gray-100 dark:border-gray-800 my-2"></li>
                        <li>
                            <a routerLink="/login"
                                class="block text-[14px] font-semibold py-1.5 px-4 hover:text-violet-600"><i
                                    class="uil uil-sign-out-alt text-[16px] align-middle me-1"></i> Logout</a>
                        </li>
                    </ul>
                </div>
            </li>
        </ul>
        <!--Login button End-->

        <div id="navigation" [ngClass]="open ? 'block' : ''">
            <!-- Navigation Menu-->
            <ul class="navigation-menu justify-end" [ngClass]="navLight ? 'nav-light' : ''">
                <li class="has-submenu parent-menu-item ms-0" [ngClass]="['/'].includes(menu) ? 'active' : ''">
                    <a [routerLink]="sitemap.getById('home')?.routerLink">{{sitemap.getById('home')?.label}}</a>
                </li>

                <li class="has-submenu parent-parent-menu-item ms-0" [ngClass]="links.includes(menu) ? 'active' : ''">
                    <a (click)="openMenu($event,'/')">Games</a><span class="menu-arrow"></span>
                    <ul class="submenu" [ngClass]="links.includes(subManu) ? 'open' : ''">
                        <li *ngFor="let tab of tabGames" class="ms-0"><a [routerLink]="tab.routerLink"
                                [queryParams]="tab.queryParams" class="sub-menu-item">{{tab.label}}</a></li>
                    </ul>
                </li>

                <li class="ms-0"><a [routerLink]="sitemap.getById('about')?.routerLink"
                        class="sub-menu-item">{{sitemap.getById('about')?.label}}</a></li>
            </ul><!--end navigation menu-->
        </div><!--end navigation-->
    </div><!--end container-->
</nav><!--end header-->
<!-- End Navbar -->