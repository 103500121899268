import { Component } from '@angular/core';
import { MatIcon } from '@angular/material/icon';

@Component({
  standalone: true,
  selector: 'app-livechat',
  templateUrl: './livechat.component.html',
  styleUrl: './livechat.component.scss',
  imports: [
    MatIcon
  ]
})
export class LivechatComponent {

}
