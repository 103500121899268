import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-combine-promo',
  templateUrl: './combine-promo.component.html',
  styleUrl: './combine-promo.component.scss'
})
export class CombinePromoComponent {

}
