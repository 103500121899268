<div class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto mt-5 bg-slate-800 rounded-lg">
    <div class="flex justify-between border-b border-slate-600">
        <div [routerLink]="sitemap.getById('referral')?.routerLink" [queryParams]="{tab: 'index'}"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            [ngClass]="currentTabState === 'index' ? 'bg-purple-950' : ''">
            Referensi
        </div>
        <div [routerLink]="sitemap.getById('referral')?.routerLink" [queryParams]="{tab: 'signup-summary'}"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Ringkasan Pendapatan
        </div>
        <div [routerLink]="sitemap.getById('referral')?.routerLink" [queryParams]="{tab: 'claimed-history'}"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Riwayat Klaim
        </div>
    </div>

    <div class="p-3 md:p-8">

        <div class="block mx-auto mt-2" data-pane="tabpane" id="index">

            <img src="/assets/images/id.webp" class="w-full" alt="">
            <p class="mt-5">Sekali ID Anda terverifikasi, Anda dapat menikmati manfaat penuh dari program referral kami:
            </p>

            <div class="p-3 bg-slate-700">
                <div class="flex items-center gap-3">
                    <div class="bg-orange-600 p-2 rounded-lg">
                        <img class="w-8"
                            src="https://dsuown9evwz4y.cloudfront.net/Images/referral/referral-commission.svg?v=20241114"
                            alt="">
                    </div>
                    <div>
                        <p class="text-sm">Komisi Referral</p>
                        <p class="text-orange-400 text-sm">Tarik komisi dari referral yang sudah Anda miliki sebelumnya
                            dan
                            nikmati hasilnya.</p>
                    </div>
                </div>
            </div>
            <div class="p-3 bg-slate-700">
                <div class="flex items-center gap-3">
                    <div class="bg-orange-600 p-2 rounded-lg">
                        <img class="w-8"
                            src="https://dsuown9evwz4y.cloudfront.net/Images/referral/referral-sharing.svg?v=20241114"
                            alt="">
                    </div>
                    <div>
                        <p class="text-sm">Kemudahan Berbagi Referral</p>
                        <p class="text-orange-400 text-sm">Berbagi kode referral Anda ke player lain dengan mudah dan
                            cepat.</p>
                    </div>
                </div>
            </div>
            <div class="p-3 bg-slate-700">
                <div class="flex items-center gap-3">
                    <div class="bg-orange-600 p-2 rounded-lg">
                        <img class="w-8"
                            src="https://dsuown9evwz4y.cloudfront.net/Images/referral/referral-summary.svg?v=20241114"
                            alt="">
                    </div>
                    <div>
                        <p class="text-sm">Ringkasan Referral</p>
                        <p class="text-orange-400 text-sm">Lihat ringkasan mengenai progress dari hasil referral Anda.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="block mx-auto mt-2" data-pane="tabpane" id="signup-summary">
            <p class="font-bold">Rentang Tanggal</p>
            <div class="flex gap-2 mt-1">
                <input type="date" class="input-custom-1 w-full md:w-[50%] bg-slate-900">
                <input type="date" class="input-custom-1 w-full md:w-[50%] bg-slate-900">
            </div>

            <div class="overflow-x-auto mt-5">
                <table class="table-auto border-collapse border border-gray-300 w-full text-sm text-gray-700">
                    <thead>
                        <tr class="bg-yellow-600">
                            <th class="border border-slate-700 px-4 py-2 text-left">Nama Pengguna</th>
                            <th class="border border-slate-700 px-4 py-2 text-left">Winlose</th>
                            <th class="border border-slate-700 px-4 py-2 text-left">Hitungan Deposit</th>
                            <th class="border border-slate-700 px-4 py-2 text-left">Jumlah</th>
                            <th class="border border-slate-700 px-4 py-2 text-left">Tanggal Login Terakhir</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
                            <td class="border border-slate-700 p-4"></td>
                            <td class="border border-slate-700 p-4"></td>
                            <td class="border border-slate-700 p-4"></td>
                            <td class="border border-slate-700 p-4"></td>
                            <td class="border border-slate-700 p-4"></td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
        <div class="block mx-auto mt-2" data-pane="tabpane" id="claimed-history">
            <div class="mt-5 p-3 md:p-6 rounded-lg bg-slate-700">
                <p class="text-yellow-500 text-sm font-bold">Catatan :</p>
                <div class="text-sm font-bold space-y-1 mt-2">
                    <div>Transaksi dengan status 'Tertunda' sedang menunggu verifikasi ID untuk diselesaikan.</div>
                </div>
            </div>

            <p class="font-bold mt-3">Rentang Tanggal</p>
            <div class="flex gap-2 mt-1">
                <input type="date" class="input-custom-1 w-full md:w-[50%] bg-slate-900">
                <input type="date" class="input-custom-1 w-full md:w-[50%] bg-slate-900">
            </div>

            <div class="overflow-x-auto mt-5">
                <table class="table-auto border-collapse border border-gray-300 w-full text-sm text-gray-700">
                    <thead>
                        <tr class="bg-yellow-600">
                            <th class="border border-slate-700 px-4 py-2 text-left">Nama Pengguna</th>
                            <th class="border border-slate-700 px-4 py-2 text-left">Winlose</th>
                            <th class="border border-slate-700 px-4 py-2 text-left">Hitungan Deposit</th>
                            <th class="border border-slate-700 px-4 py-2 text-left">Jumlah</th>
                            <th class="border border-slate-700 px-4 py-2 text-left">Tanggal Login Terakhir</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
                            <td class="border border-slate-700 p-4"></td>
                            <td class="border border-slate-700 p-4"></td>
                            <td class="border border-slate-700 p-4"></td>
                            <td class="border border-slate-700 p-4"></td>
                            <td class="border border-slate-700 p-4"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </div>

</div>