import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import sitemap from '../../../../shared/utils/sitemap';
import { CommonModule } from '@angular/common';
import { switchTab } from '../../../../shared/utils/utils';

@Component({
	standalone: true,
	selector: 'app-referral',
	templateUrl: './referral.component.html',
	styleUrl: './referral.component.scss',
	imports: [
		RouterLink,
		CommonModule,
		RouterLinkActive
	]
})
export class ReferralComponent implements OnInit {
	sitemap = sitemap
	currentTabState = ''
	constructor(private readonly router: Router) { }
	ngOnInit(): void {
		switchTab({
			tabName: 'tabpane',
			defaultTab: 'index',
			queryParams: location.search,
			onChange: (tab) => {
				this.currentTabState = tab
			},
		})
		this.router.events.subscribe(ev => {
			if (ev instanceof NavigationEnd) {
				switchTab({
					tabName: 'tabpane',
					defaultTab: 'index',
					queryParams: location.search,
					onChange: (tab) => {
						this.currentTabState = tab
					},
				})
			}
		})
	}
}
