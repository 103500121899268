import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import sitemap from '../../../../shared/utils/sitemap';
import { switchTab, textFormatDetection } from '../../../../shared/utils/utils';
import { ButtonComponent } from '../../../../shared/components/button/button.component';

@Component({
  standalone: true,
  selector: 'app-banking',
  templateUrl: './banking.component.html',
  styleUrl: './banking.component.scss',
  imports: [
    CommonModule,
    RouterModule,
    ButtonComponent
  ]
})
export class BankingComponent implements OnInit {
  sitemap = sitemap
  
  constructor(private readonly router: Router) { }
  ngOnInit(): void {
      
  }
}
