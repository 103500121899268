<app-navbar />

<section class="relative lg:pt-24 pt-[74px] overflow-hidden">
    <div class="container-fluid lg:px-10 md:px-3 relative overflow-hidden">

        <!-- carousel use prime library -->
        <p-carousel *ngIf="!hideComponent" [value]="banners" [numVisible]="1" [numScroll]="1" [circular]="false"
            [autoplayInterval]="5000" [responsiveOptions]="responsiveOptions">
            <ng-template let-banner pTemplate="item">
                <div class="md:px-24">
                    <div class="mb-3">
                        <div class="relative mx-auto">
                            <a [routerLink]="banner.clickable ? banner.clickLink : null">
                                <img src="{{ banner.image.desktop }}" [alt]="banner.name"
                                    class="w-full rounded-lg hidden md:block" />
                                <img src="{{ banner.image.mobile }}" [alt]="banner.name"
                                    class="w-full rounded-lg block md:hidden" />
                            </a>
                        </div>
                    </div>
                </div>
            </ng-template>
        </p-carousel>

        <app-tab-games *ngIf="!hideComponent" />

        <div class="md:block md:mt-0 -mt-1">
            <div class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto px-2">
                <router-outlet />
            </div>
        </div>

    </div>
</section>

<app-livechat />

<app-footer [props]="{
    brandingText: 'Nama Brand'
}" />