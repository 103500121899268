<div class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto mt-5 bg-slate-800 rounded-lg">
    <div class="flex justify-between border-b border-slate-600">
        <div [routerLink]="profileRoute?.routerLink" [queryParams]="profileRoute?.queryParams[0]"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            [ngClass]="currentTabState === profileRoute?.queryParams[0]?.tab ? 'bg-purple-950' : ''">
            Akun Saya
        </div>
        <div [routerLink]="profileRoute?.routerLink" [queryParams]="profileRoute?.queryParams[1]"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            [ngClass]="currentTabState === profileRoute?.queryParams[1]?.tab ? 'bg-purple-950' : ''">
            Ubah Kata Sandi
        </div>
    </div>

    <div class="p-3 md:p-8 bg-slate-800" *ngIf="currentTabState === profileRoute?.queryParams[0]?.tab">

        <!-- Personal Information -->
        <div>
            <h2 class="border-l-4 border-purple-600 pl-3 uppercase font-bold">Informasi Pribadi</h2>

            <div class="mt-6 space-y-4 px-4">
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Nama Lengkap</p>
                    <input class="input-custom-1 w-[65%] bg-slate-900" value="Dani Sycolous" readonly>
                </div>
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Jenis Kelamin</p>
                    <select class="input-custom-1 w-[65%] bg-slate-900">
                        <option value="">-- Pilih Jenis Kelamin --</option>
                        <option value="laki-laki">Laki Laki</option>
                        <option value="perempuan">Perempuan</option>
                    </select>
                </div>
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Alamat</p>
                    <textarea class="input-custom-1 w-[65%] bg-slate-900" placeholder="Alamat Anda"></textarea>
                </div>
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Kode Pos</p>
                    <input class="input-custom-1 w-[65%] bg-slate-900" placeholder="Kode Pos Anda">
                </div>
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Propinsi</p>
                    <input class="input-custom-1 w-[65%] bg-slate-900" placeholder="Propinsi Anda">
                </div>
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Jenis Kelamin</p>
                    <select class="input-custom-1 w-[65%] bg-slate-900">
                        <option value="">-- Pilih Negara --</option>
                        <option value="Indonesia">Indonesia</option>
                    </select>
                </div>
            </div>
        </div>


        <!-- Contact Informations -->
        <div class="mt-9">
            <h2 class="border-l-4 border-purple-600 pl-3 uppercase font-bold">Informasi Kontak</h2>

            <div class="mt-6 space-y-4 px-4">
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Nomor Kontak</p>
                    <input type="tel" class="input-custom-1 w-[65%] bg-slate-900" placeholder="Nomor Kontak Anda">
                </div>
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Email</p>
                    <input type="email" class="input-custom-1 w-[65%] bg-slate-900" placeholder="Email Kontak Anda"
                        value="example@example.com">
                </div>
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">Telegram</p>
                    <input type="tel" class="input-custom-1 w-[65%] bg-slate-900" placeholder="Telegram Anda">
                </div>
                <div class="flex gap-3 items-center">
                    <p class="text-nowrap w-[35%]">WhatsApp</p>
                    <input type="tel" class="input-custom-1 w-[65%] bg-slate-900" placeholder="WhatsApp Anda">
                </div>

            </div>
        </div>

        <div class="mt-6 p-3 md:p-8 w-full flex justify-end">
            <app-button variant="warning">Simpan Data Profil Saya</app-button>
        </div>

    </div>

    <div class="p-3 md:p-8 bg-slate-800" *ngIf="currentTabState === profileRoute?.queryParams[1]?.tab">
        <div class="p-3 md:p-6 rounded-lg bg-slate-700">
            <p class="text-yellow-500 text-sm font-bold">Catatan</p>
            <div class="text-sm font-bold space-y-1 mt-2">
                <div>1. Kata Sandi harus terdiri dari 8-20 karakter.</div>
                <div>2. Kata Sandi harus mengandung huruf dan angka.</div>
                <div>3. Kata Sandi tidak boleh mengandung username.</div>
            </div>
        </div>


        <div class="mt-6 space-y-4 px-4">
            <div class="flex gap-3 items-center">
                <p class="text-nowrap w-[35%]">Kata Sandi Saat Ini</p>
                <input type="password" class="input-custom-1 w-[65%] bg-slate-900" placeholder="Kata Sandi Saat Ini">
            </div>
            <div class="flex gap-3 items-center">
                <p class="text-nowrap w-[35%]">Kata Sandi Baru</p>
                <input type="password" class="input-custom-1 w-[65%] bg-slate-900" placeholder="Kata Sandi Baru">
            </div>
            <div class="flex gap-3 items-center">
                <p class="text-nowrap w-[35%]">Ulangi Kata Sandi</p>
                <input type="password" class="input-custom-1 w-[65%] bg-slate-900" placeholder="Ulangi Kata Sandi">
            </div>
            <div class="flex gap-3 items-center">
                <p class="text-nowrap w-[35%]">Kode Verifikasi</p>
                <div class="flex w-[65%]">
                    <div class="relative w-full">
                        <input type="text" class="input-custom-1 bg-slate-900 w-full rounded-tr-none rounded-br-none" placeholder="Kode Verikasi">
                        <div class="absolute top-3 right-3 cursor-pointer"><span
                                class="material-symbols-outlined">sync</span></div>
                    </div>
                    <!-- TODO: mengubah ini dan dapatkan dari server sendiri -->
                    <img class="rounded-tr-lg rounded-br-lg" src="https://ckatu.xyz/captcha" alt="">
                </div>
            </div>

            <div class="mt-6 p-2 w-full flex justify-end">
                <app-button variant="warning">Simpan Data Profil Saya</app-button>
            </div>
        </div>
    </div>

</div>