[
    {
        "name": "Bank BCA",
        "image": "https://dsuown9evwz4y.cloudfront.net/Images/banks/bca.svg",
        "accountNumber": "123456789",
        "accountHolder": "Dimas Satya Nugraha",
        "operational": true,
        "group": "bank"
    },
    {
        "name": "Bank BCA",
        "image": "https://dsuown9evwz4y.cloudfront.net/Images/banks/bca.svg",
        "accountNumber": "123456789",
        "accountHolder": "Dimas Satya Nugraha",
        "operational": true,
        "group": "bank"
    },
    {
        "name": "Bank BCA",
        "image": "https://dsuown9evwz4y.cloudfront.net/Images/banks/bca.svg",
        "accountNumber": "123456789",
        "accountHolder": "Dimas Satya Nugraha",
        "operational": true,
        "group": "bank"
    },
    {
        "name": "Dana",
        "image": "https://dsuown9evwz4y.cloudfront.net/Images/bank-thumbnails/DANA.webp?v=20241103-1",
        "accountNumber": "123456789",
        "accountHolder": "Dimas Satya Nugraha",
        "operational": true,
        "group": "ewallet"
    },
    {
        "name": "Bank BCA",
        "image": "https://dsuown9evwz4y.cloudfront.net/Images/banks/bca.svg",
        "accountNumber": "123456789",
        "accountHolder": "Dimas Satya Nugraha",
        "operational": true,
        "group": "bank"
    },
    {
        "name": "Bank BCA",
        "image": "https://dsuown9evwz4y.cloudfront.net/Images/banks/bca.svg",
        "accountNumber": "123456789",
        "accountHolder": "Dimas Satya Nugraha",
        "operational": true,
        "group": "bank"
    }
]