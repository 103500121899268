import { Component, Input } from '@angular/core';
import { MatIcon } from '@angular/material/icon';
import { MatCardModule } from '@angular/material/card';
import { RouterLink, RouterLinkActive } from '@angular/router';
import { CommonModule } from '@angular/common';
import TabGames from '../../../../data/tab-games.json'
@Component({
    standalone: true,
    selector: 'app-tab-games',
    templateUrl: './tab-games.component.html',
    styleUrl: './tab-games.component.scss',
    imports: [
        MatIcon,
        MatCardModule,
        RouterLink,
        CommonModule,
        RouterLinkActive
    ]
})
export class TabGamesComponent {
    tabItems = TabGames
    @Input() fromNavMobile = false
    isActivePath(path: string) {
        return new RegExp(path).test(location.pathname)
    }
    isActiveCondition(classConditions: string[]): boolean {
        return classConditions.some(condition => this.isActivePath(condition));
    }

    goTab(tab: string, selector: HTMLDivElement) {
        const tabContent = document.querySelector(tab)
        const tabSels = document.querySelectorAll('[data-pane="tabselector"]')
        const tabPanes = document.querySelectorAll('[data-pane="tabpane"]')
        tabPanes.forEach(pane => {
            pane.classList.remove('block')
            pane.classList.add('hidden')
        })
        tabSels.forEach(sel => {
            sel.classList.remove('bg-purple-950')
        })

        if (tabContent) {
            tabContent.classList.remove('hidden')
            tabContent.classList.add('block')
            selector.classList.add('bg-purple-950')
        }
    }
}
