[
    {
        "name": "Bamboo Watch",
        "description": "Product Description",
        "image": {
            "desktop": "assets/images/items/dummy-banner.jpg",
            "mobile": "assets/images/items/dummy-banner-mobile.jpg"
        },
        "clickLink": "/path/to/banner/action-click",
        "clickable": false
    },
    {
        "name": "Bamboo Watch",
        "description": "Product Description",
        "image": {
            "desktop": "assets/images/items/dummy-banner.jpg",
            "mobile": "assets/images/items/dummy-banner-mobile.jpg"
        },
        "clickLink": "/path/to/banner/action-click",
        "clickable": true
    },
    {
        "name": "Bamboo Watch",
        "description": "Product Description",
        "image": {
            "desktop": "assets/images/items/dummy-banner.jpg",
            "mobile": "assets/images/items/dummy-banner-mobile.jpg"
        },
        "clickLink": "/path/to/banner/action-click",
        "clickable": true
    },
    {
        "name": "Bamboo Watch",
        "description": "Product Description",
        "image": {
            "desktop": "assets/images/items/dummy-banner.jpg",
            "mobile": "assets/images/items/dummy-banner-mobile.jpg"
        },
        "clickLink": "/path/to/banner/action-click",
        "clickable": true
    },
    {
        "name": "Bamboo Watch",
        "description": "Product Description",
        "image": {
            "desktop": "assets/images/items/dummy-banner.jpg",
            "mobile": "assets/images/items/dummy-banner-mobile.jpg"
        },
        "clickLink": "/path/to/banner/action-click",
        "clickable": true
    },
    {
        "name": "Bamboo Watch",
        "description": "Product Description",
        "image": {
            "desktop": "assets/images/items/dummy-banner.jpg",
            "mobile": "assets/images/items/dummy-banner-mobile.jpg"
        },
        "clickLink": "/path/to/banner/action-click",
        "clickable": true
    },
    {
        "name": "Bamboo Watch",
        "description": "Product Description",
        "image": {
            "desktop": "assets/images/items/dummy-banner.jpg",
            "mobile": "assets/images/items/dummy-banner-mobile.jpg"
        },
        "clickLink": "/path/to/banner/action-click",
        "clickable": true
    }
]