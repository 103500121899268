import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserPagesComponent } from './user-pages.component';
import { UserDashboardComponent } from './components/user-dashboard/user-dashboard.component';
import { TogelPageComponent } from './components/dynamic-pages/togel-page/togel-page.component';
import { AnyPageComponent } from './components/dynamic-pages/any-page/any-page.component';
import { ProfileComponent } from './components/user/profile/profile.component';
import { DepositComponent } from './components/user/deposit/deposit.component';
import { WithdrawComponent } from './components/user/withdraw/withdraw.component';
import { BankingComponent } from './components/user/banking/banking.component';
import { BonusComponent } from './components/user/bonus/bonus.component';
import { ReferralComponent } from './components/user/referral/referral.component';
import { WapVersionComponent } from '../wap/wap-version/wap-version.component';

const routes: Routes = [
  {
    path: '',
    component: UserPagesComponent,
    canActivate: [],
    canActivateChild: [],
    children: [
      {
        path: 'user/landing',
        component: UserDashboardComponent
      },
      {
        path: 'user/lottery',
        component: TogelPageComponent
      },
      {
        path: 'user/profile',
        component: ProfileComponent
      },
      {
        path: 'user/deposit',
        component: DepositComponent
      },
      {
        path: 'user/withdraw',
        component: WithdrawComponent
      },
      {
        path: 'user/banking',
        component: BankingComponent
      },
      {
        path: 'user/bonus',
        component: BonusComponent
      },
      {
        path: 'user/referral',
        component: ReferralComponent
      },
      // path dynamic
      {
        path: 'user/:tabid',
        component: AnyPageComponent
      },
      {
        path: 'user/:tabid/:provider',
        component: AnyPageComponent
      },


    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UserPagesRoutingModule { }
