<button [disabled]="disabled" [ngClass]="{
    'bg-green-500 text-white focus:ring-green-300': variant === 'success',
    'bg-red-500 text-white focus:ring-red-300': variant === 'danger',
    'bg-yellow-500 text-black focus:ring-yellow-300': variant === 'warning',
    'bg-blue-500 text-white focus:ring-blue-300': variant === 'info',
    'bg-gray-800 text-white focus:ring-gray-500': variant === 'dark',
    'border border-gray-500 text-gray-500 focus:ring-gray-300': variant === 'outline',
    'bg-gray-100 text-black focus:ring-gray-300': variant === 'light'
  }"
    class="appearance-none px-4 py-2 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 duration-200 transition-all {{class}}">
    <ng-container *ngIf="isLoading; else content">
        <div class="flex items-center">
            <app-loading-indicator />
            <span class="ml-2">{{loadingtext}}</span>
        </div>
    </ng-container>

    <ng-template #content>
        <ng-content></ng-content>
    </ng-template>
</button>