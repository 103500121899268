import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-commission',
  templateUrl: './commission.component.html',
  styleUrl: './commission.component.scss'
})
export class CommissionComponent {

}
