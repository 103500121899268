[
    {
        "id": "home",
        "routerLink": "/user/home",
        "queryParams": null,
        "label": "Home"
    },
    {
        "id": "profile",
        "routerLink": "/user/profile",
        "queryParams": [
            {
                "tab": "account"
            },
            {
                "tab": "change-password"
            }
        ],
        "label": "Profile"
    },
    {
        "id": "deposit",
        "routerLink": "/user/deposit",
        "queryParams": null,
        "label": "Deposit"
    },
    {
        "id": "withdraw",
        "routerLink": "/user/withdraw",
        "queryParams": null,
        "label": "Withdraw"
    },
    {
        "id": "banking",
        "routerLink": "/user/banking",
        "queryParams": null,
        "label": "Banking"
    },
    {
        "id": "bonus",
        "routerLink": "/user/bonus",
        "queryParams": null,
        "label": "Bonus"
    },
    {
        "id": "referral",
        "routerLink": "/user/referral",
        "queryParams": null,
        "label": "Referral"
    },
    {
        "id": "about",
        "routerLink": "/about",
        "queryParams": null,
        "label": "About"
    }
]