import { Component, Input } from '@angular/core';

export interface IFooterData {
  brandingText: string
}

@Component({
  standalone: true,
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  @Input() props!: IFooterData
}
