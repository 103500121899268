import { Component } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-cashback',
  templateUrl: './cashback.component.html',
  styleUrl: './cashback.component.scss'
})
export class CashbackComponent {

}
