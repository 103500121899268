// @ts-nocheck
import URLMAP from '../../../data/sitemap.json';

interface SitemapItem {
    id: string;
    routerLink: string;
    queryParams?: any;
    label: string;
}

interface SitemapMethods {
    get(selector: Partial<SitemapItem>): SitemapItem | undefined;
    getById(id: string): SitemapItem | undefined;
    getByRouterLink(routerLink: string): SitemapItem | undefined;
    getAll(): SitemapItem[];
    filterById(label: string | string[]): SitemapItem[];
    excludeById(label: string | string[]): SitemapItem[];
    getPaths(): string[];
    add(item: SitemapItem): boolean;
    removeById(id: string): boolean;
}

// Tambahkan fungsi `_call` untuk mendapatkan URLMAP secara langsung
const SitemapHandler: ProxyHandler<SitemapMethods & SitemapItem[]> = {
    get(target, prop) {
        if (prop === 'data') return URLMAP;

        // Menambahkan _call sebagai fungsi callable
        if (prop === '_call') return () => URLMAP;

        // Mendukung metode array seperti map, forEach, dll.
        const arrayMethods = ['map', 'forEach', 'filter', 'reduce', 'some', 'every', 'find', 'findIndex'];
        if (arrayMethods.includes(prop as string)) {
            const method = (URLMAP as SitemapItem[])[prop as keyof SitemapItem[]];
            if (typeof method === 'function') {
                return method.bind(URLMAP);
            }
        }

        // Mengembalikan metode atau properti pada target
        if (prop in target) {
            return target[prop as keyof SitemapMethods];
        }

        return undefined;
    }
};

const sitemap: SitemapMethods & SitemapItem[] & { (): SitemapItem[] } = new Proxy(
    {
        /**
         * Menemukan item yang sesuai dengan selector.
         */
        get(selector: Partial<SitemapItem>): SitemapItem | undefined {
            return URLMAP.find(
                item =>
                    (selector.id && item.id === selector.id) ||
                    (selector.routerLink && item.routerLink === selector.routerLink) ||
                    (selector.label && item.label === selector.label)
            );
        },

        /**
         * Mendapatkan item berdasarkan id.
         */
        getById(id: string): SitemapItem | undefined {
            return URLMAP.find(item => item.id === id);
        },

        /**
         * Mendapatkan item berdasarkan routerLink.
         */
        getByRouterLink(routerLink: string): SitemapItem | undefined {
            return URLMAP.find(item => item.routerLink === routerLink);
        },

        /**
         * Mengembalikan semua item dari URLMAP.
         */
        getAll(): SitemapItem[] {
            return URLMAP;
        },

        /**
         * Menyaring item berdasarkan label yang mengandung teks tertentu.
         * Menerima string tunggal atau array string sebagai parameter.
         */
        filterById(id: string | string[]): SitemapItem[] {
            if (typeof id === 'string') {
                return URLMAP.filter(item => item.id.toLowerCase().includes(id.toLowerCase()));
            } else {
                return URLMAP.filter(item =>
                    id.some(lbl => item.id.toLowerCase().includes(lbl.toLowerCase()))
                );
            }
        },

        /**
         * Mengecualikan item berdasarkan id yang mengandung teks tertentu.
         * Menerima string tunggal atau array string sebagai parameter.
         */
        excludeById(id: string | string[]): SitemapItem[] {
            if (typeof id === 'string') {
                return URLMAP.filter(item => !item.id.toLowerCase().includes(id.toLowerCase()));
            } else {
                return URLMAP.filter(item =>
                    !id.some(lbl => item.id.toLowerCase().includes(lbl.toLowerCase()))
                );
            }
        },

        /**
         * Mendapatkan semua routerLink dari URLMAP.
         */
        getPaths(): string[] {
            return URLMAP.map(item => item.routerLink);
        },

        /**
         * Menambahkan item baru ke URLMAP jika id belum ada.
         */
        add(item: SitemapItem): boolean {
            if (this.getById(item.id)) {
                console.warn(`Item dengan id ${item.id} sudah ada.`);
                return false;
            }
            URLMAP.push(item);
            return true;
        },

        /**
         * Menghapus item dari URLMAP berdasarkan id.
         */
        removeById(id: string): boolean {
            const index = URLMAP.findIndex(item => item.id === id);
            if (index === -1) {
                console.warn(`Item dengan id ${id} tidak ditemukan.`);
                return false;
            }
            URLMAP.splice(index, 1);
            return true;
        }
    },
    SitemapHandler
);

// Tambahkan properti fungsi untuk memanggil URLMAP secara langsung
Object.defineProperty(sitemap, 'call', {
    value: sitemap._call,
    configurable: true,
    writable: true,
});


export default sitemap;


// sitemap.map()