<div class="md:block md:mt-0 -mt-10">
    <div class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto px-2 pb-5">
        <div class="flex justify-between items-center gap-2 overflow-auto mx-auto z-0 !w-full mt-10 md:mt-5 pb-3">
            <!-- isActiveCondition(tab.classCondition) ? 'border-yellow-600' : 'border-transparent' -->
            @for (tab of tabItems; track $index) {
            <a [routerLink]="tab.routerLink" [queryParams]="tab.queryParams" routerLinkActive #rla="routerLinkActive">
                <mat-card class="bg-slate-800 w-28 h-[6rem] p-3 flex flex-col justify-end items-center border"
                    [ngClass]="rla.isActive ? 'border-yellow-600' : 'border-transparent'">
                    <img [src]="tab.image" [alt]="tab.label" width="28px">
                    <p class="mb-0 mt-2 text-xs font-bold text-nowrap">{{tab.label}}</p>
                </mat-card>
            </a>
            }
        </div>
    </div>

</div>