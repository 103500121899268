import { Component, Input, OnInit } from '@angular/core';
import TabGames from '../../../../../data/tab-games.json';
import Games from '../../../../../data/games.json';
import { ActivatedRoute, Router } from '@angular/router';
import { ITabGames } from '../../../../../data/types/tab-games';
import { IGames } from '../../../../../data/types/games';
import { MyAlert } from '../../../../shared/utils/app.alert';

@Component({
    selector: 'app-any-page',
    templateUrl: './any-page.component.html',
    styleUrls: ['./any-page.component.scss']
})
export class AnyPageComponent implements OnInit {
    activeTab?: ITabGames;
    gamesData?: IGames[];
    originalGamesData?: IGames[];
    isPathProvider = true;
    providerName?: null | string;
    isChildOnParent: boolean = false

    constructor(private activeRoute: ActivatedRoute, private router: Router) { }

    ngOnInit(): void {
        this.getActiveTab();
        this.findItemByUrl();
        this.activeRoute.paramMap.subscribe({
            next: (_par) => {
                this.getActiveTab()
                if (location.pathname.split('/').length === 4) {
                    this.isPathProvider = false
                    this.findItemByUrl()
                }
                if (this.activeTab?.classCondition[0] === 'hot-games') {
                    this.isPathProvider = false
                    this.isChildOnParent = true
                    this.gamesData = this.activeTab.children as any
                    this.providerName = this.activeTab.label
                } else {
                    this.isChildOnParent = false
                    this.findItemByUrl()
                }
            }
        })
    }

    findItemByUrl() {
        // TODO: request to server for find games data by provider
        const url = this.activeRoute.snapshot.url[2] || null
        if (url) {
            this.isPathProvider = false
            this.gamesData = Games.filter(game => game.provider.id === url.path)
            this.providerName = this.gamesData[0]?.provider?.label
        } else {
            this.isPathProvider = true
            this.providerName = null
        }
    }

    getActiveTab(): void {
        const foundTab = TabGames.find(tab => tab.classCondition.some(condition => new RegExp(condition).test(location.pathname))) as any;
        this.activeTab = foundTab;
    }

    isVideo(src: string): boolean {
        const videoExtensions = [
            'mp4', 'mov', 'wmv', 'avi', 'mkv', 'flv', 'webm', 'ogg', 'm4v', '3gp'
        ];
        const cleanSrc = src.split('?')[0];
        const extension = cleanSrc.split('.').pop()?.toLowerCase();
        return extension ? videoExtensions.includes(extension) : false;
    }

    onSearching(e: Event) {
        if (!this.originalGamesData) {
            this.originalGamesData = this.gamesData?.slice() || []; // Salin data asli
        }
        const target = e.target as HTMLInputElement;
        if (!target) {
            console.warn('Event target is not an HTMLInputElement');
            return;
        }
        const searchValue = target.value?.toLowerCase().trim() || '';
        if (!searchValue) {
            this.gamesData = this.originalGamesData.slice();
            return;
        }
        this.gamesData = this.originalGamesData.filter(game =>
            game.label.toLowerCase().includes(searchValue)
        );
    }

    pop() {
        history.back()
    }

    alert(title: string) {
        MyAlert.show(title)
    }
}