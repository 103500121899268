import { Component, Input } from '@angular/core';


@Component({
  standalone: true,
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent {
  @Input() variant = 'spinner';
}
