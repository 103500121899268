<footer class="bg-slate-800 px-10 p-3 text-white mt-16">
    <div class="mx-auto z-0 !w-full !max-w-[1050px]">
        <!-- section payments -->
        <div class="md:block">
            <div class="flex justify-between">
                <div>
                    <p class="text-[13px] mb-1 text-slate-300">Bersertifikasi Dari</p>
                    <div class="flex gap-3">
                        <div>
                            <img class="rounded-lg grayscale hover:grayscale-0 duration-200 transition-all ease-in"
                                width="100px" src="/assets/images/footers/vin-gaming-active.webp" alt="">
                        </div>
                    </div>
                </div>

                <div class="flex flex-col">
                    <p class="text-[13px] mb-1 text-slate-300">Tanggung Jawab Bermain</p>
                    <div class="flex gap-3 md:justify-start justify-end">
                        <div>
                            <img class="rounded-lg grayscale hover:grayscale-0 duration-200 transition-all ease-in"
                                width="65px" src="/assets/images/footers/gambling-support-active.webp" alt="">
                        </div>
                        <div>
                            <img class="rounded-lg grayscale hover:grayscale-0 duration-200 transition-all ease-in"
                                width="35px" src="/assets/images/footers/18-plus-active.webp" alt="">
                        </div>
                    </div>
                </div>

                <div class="flex-col hidden md:flex">
                    <p class="text-[13px] mb-1 text-slate-300 text-end">Browser Disarankan</p>
                    <div class="flex gap-3 justify-end">
                        <div>
                            <img class="rounded-lg grayscale hover:grayscale-0 duration-200 transition-all ease-in"
                                width="35px" src="/assets/images/footers/chrome-active.webp" alt="">
                        </div>
                        <div>
                            <img class="rounded-lg grayscale hover:grayscale-0 duration-200 transition-all ease-in"
                                width="35px" src="/assets/images/footers/edge-active.webp" alt="">
                        </div>
                        <div>
                            <img class="rounded-lg grayscale hover:grayscale-0 duration-200 transition-all ease-in"
                                width="35px" src="/assets/images/footers/firefox-active.webp" alt="">
                        </div>
                    </div>
                </div>

            </div>
        </div>


        <!-- section browser if mobile -->
        <div class="md:hidden border-t border-dashed border-gray-400 mt-5 mb-3"></div>
        <div class="md:hidden">
            <div class="flex flex-col">
                <p class="text-[13px] mb-1 text-slate-300">Browser Disarankan</p>
                <div class="flex gap-3">
                    <div>
                        <img class="rounded-lg grayscale hover:grayscale-0 duration-200 transition-all ease-in"
                            width="35px" src="/assets/images/footers/chrome-active.webp" alt="">
                    </div>
                    <div>
                        <img class="rounded-lg grayscale hover:grayscale-0 duration-200 transition-all ease-in"
                            width="35px" src="/assets/images/footers/edge-active.webp" alt="">
                    </div>
                    <div>
                        <img class="rounded-lg grayscale hover:grayscale-0 duration-200 transition-all ease-in"
                            width="35px" src="/assets/images/footers/firefox-active.webp" alt="">
                    </div>
                </div>
            </div>
        </div>

        <!-- section payments -->
        <div class="border-t border-dashed border-gray-400 mt-5 mb-3"></div>
        <div class="">
            <div class="flex justify-between">
                <div>
                    <p class="text-[13px] mb-1 text-slate-300">Metode Pembayaran</p>
                    <div class="flex gap-3">
                        <div>
                            <img class="rounded-lg" width="85px" src="/assets/images/footers/bank_col.jpg" alt="">
                        </div>
                        <div>
                            <img class="rounded-lg" width="85px" src="/assets/images/footers/ewallet_col.webp" alt="">
                        </div>
                        <div>
                            <img class="rounded-lg" width="85px" src="/assets/images/footers/pulsa_col.webp" alt="">
                        </div>
                        <div>
                            <img class="rounded-lg" width="85px" src="/assets/images/footers/cryptocurrency_col.webp" alt="">
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <!-- section game providers -->
        <div class="hidden md:block border-t border-dashed border-gray-400 mt-5 mb-3"></div>
        <div class="hidden md:block">
            <p class="text-[13px] mb-1 text-slate-300">Game Provider</p>
            <div>
                <img src="/assets/images/footers/footer_provider_white.webp" alt="">
            </div>
        </div>
    </div>

</footer>

<!-- footer copyright desktop -->
<footer class="bg-slate-900 px-10 p-5 text-white hiddens md:block">
    <div class="md:hidden flex justify-center items-center my-5 gap-5">
        <nav class="text-white text-xs space-x-4 text-center">
            <a href="" class="border-r border-white pr-4">Tentang {{props.brandingText}}</a>
            <a href="" class="border-r border-white pr-4">Responsible Gambling</a>
            <a href="" class="border-r border-white pr-4">Pusat Bantuan</a>
            <a href="">Syarat dan Ketentuan</a>
        </nav>
    </div>

    <div class="flex justify-center md:justify-between mx-auto z-0 !w-full !max-w-[1050px]">
        <div>
            <p class="text-xs">©2024 <a href="" class="text-yellow-500">{{props.brandingText}}</a>. Hak cipta dilindungi
                | 18+
            </p>
        </div>
        <div class="hidden md:flex justify-center items-center">
            <nav class="text-white text-xs space-x-4">
                <a href="" class="border-r border-white pr-4">Tentang {{props.brandingText}}</a>
                <a href="" class="border-r border-white pr-4">Responsible Gambling</a>
                <a href="" class="border-r border-white pr-4">Pusat Bantuan</a>
                <a href="">Syarat dan Ketentuan</a>
            </nav>
        </div>
    </div>
</footer>
