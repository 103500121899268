<!-- Jika display provider -->
<div *ngIf="isPathProvider" class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto mt-5 bg-slate-700 rounded-lg">
    <div class="flex items-center px-4">
        <div class="p-3 px-4 flex gap-3 items-center">
            <img [src]="activeTab?.image" [alt]="activeTab?.label">
            <p class="font-bold">{{ activeTab?.label }}</p>
        </div>
    </div>

    <div class="md:p-4 p-2 bg-slate-800">
        <div class="grid lg:grid-cols-4 md:grid-cols-3 grid-cols-2 gap-4">
            <div *ngFor="let item of activeTab?.children"
                class="group relative overflow-hidden p-2 rounded-lg bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit"
                [routerLink]="[item.routerLink]">
                <div class="relative overflow-hidden">
                    <div class="relative overflow-hidden rounded-lg w-full">
                        @if (isVideo(item.image)) {
                        <video [autoplay]="true" loop [muted]="true" [playsInline]="true" width="100%" height="100%"
                            [src]="item.image">
                        </video>
                        }@else {
                        <img [src]="item.image"
                            class="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500"
                            alt="" width="100%">
                        }
                    </div>

                    <div
                        class="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                        <a [routerLink]="[item.routerLink]"
                            class="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white text-sm"><i
                                class="mdi mdi-controller"></i> Mainkan</a>
                    </div>
                </div>

                <div class="mt-3 px-1">

                    <div class="my-3 text-start">
                        <a [routerLink]="[item.routerLink]"
                            class="font-semibold hover:text-violet-600 text-sm">{{activeTab?.label}}</a>
                    </div>

                    <div class="flex p-2 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700">
                        <div>
                            <span class="text-xs font-semibold flex gap-2"><i class="mdi mdi-controller"></i> {{
                                item.label }}</span>
                        </div>
                    </div>

                </div>
            </div>

            <!-- untuk type activeTab.type === 'children' -->


        </div>

    </div>
</div>

<!-- Jika display game list dari provider -->
<div *ngIf="!isPathProvider" class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto mt-5 bg-slate-800/50 rounded-lg">
    <div class="flex items-center px-4">

        <!-- display grid provider -->
        <div class="mx-auto z-0 !w-full !max-w-[950px] overflow-auto"
            [ngClass]="!isChildOnParent&&activeTab?.children === undefined || activeTab?.children?.length !== 0 ? 'p-3' : ''"
            *ngIf="!isChildOnParent">
            <div class="flex gap-2 justify-between items-center">
                <div class="p-2 bg-slate-900 border border-transparent rounded-lg group cursor-pointer transition-all duration-500 hover:-mt-2 min-w-28"
                    *ngFor="let item of activeTab?.children" [routerLink]="[activeTab?.routerLink, item.routerLink]"
                    routerLinkActive="bg-slate-950 border border-orange-400" #rla="routerLinkActive">
                    @if (isVideo(item.image)) {
                    <video [autoplay]="true" loop [muted]="true" [playsInline]="true" width="100%" height="100%"
                        [src]="item.image"
                        class="rounded-lg shadow-md group-hover:scale-110 transition-all duration-500">
                    </video>
                    }@else {
                    <img [src]="item.image"
                        class="rounded-lg shadow-md group-hover:scale-110 transition-all duration-500" alt=""
                        width="100%">
                    }
                    <p class="font-bold text-sm mt-3 text-center text-nowrap text-ellipsis">{{ item.label }}</p>
                </div>
            </div>
        </div>

    </div>
    <!-- Label Title -->
    <div class="md:p-4 p-2 bg-slate-700 block md:flex justify-between">
        <div class="p-3 px-4 flex gap-3 items-center">
            <img [src]="activeTab?.image" [alt]="activeTab?.label">
            <p class="font-bold">{{ providerName }}</p>
        </div>
        <div class="flex items-center gap-2 relative">
            <span class="material-symbols-outlined absolute ml-3 opacity-60">search</span>
            <!-- @ts-ignore -->
            <input type="search" class="input-custom-1 w-full pl-11" placeholder="Cari games..." (input)="onSearching($event)" #inputEvent>
        </div>
    </div>


    <!-- Display Games List -->
    <div class="md:p-4 p-2 bg-slate-800">
        <div class="grid lg:grid-cols-5 md:grid-cols-4 grid-cols-2 gap-4">
            <div *ngFor="let item of gamesData"
                class="group relative overflow-hidden p-2 rounded-lg bg-white dark:bg-slate-900 border border-gray-100 dark:border-gray-800 hover:shadow-md dark:shadow-md hover:dark:shadow-gray-700 transition-all duration-500 hover:-mt-2 h-fit"
                [routerLink]="[item.path]">
                <div class="relative overflow-hidden">
                    <div class="relative overflow-hidden rounded-lg w-full">
                        @if (isVideo(item.image)) {
                        <video [autoplay]="true" loop [muted]="true" [playsInline]="true" width="100%" height="100%"
                            [src]="item.image">
                        </video>
                        }@else {
                        <img [src]="item.image"
                            class="rounded-lg shadow-md dark:shadow-gray-700 group-hover:scale-110 transition-all duration-500"
                            alt="" width="100%">
                        }
                    </div>

                    <div
                        class="absolute -bottom-20 group-hover:bottom-1/2 group-hover:translate-y-1/2 start-0 end-0 mx-auto text-center transition-all duration-500">
                        <a [routerLink]="[item.path]" (click)="alert(item.label)"
                            class="btn btn-sm rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white text-sm"><i
                                class="mdi mdi-controller"></i> Mainkan</a>
                    </div>
                </div>

                <div class="mt-3 px-1">

                    <div class="my-3 text-start">
                        <a [routerLink]="[item.path]"
                            class="font-semibold hover:text-violet-600 text-sm">{{item.label}}</a>
                    </div>

                    <div class="flex p-2 bg-gray-50 dark:bg-slate-800 rounded-lg shadow dark:shadow-gray-700">
                        <div>
                            <span class="text-xs font-semibold flex gap-2 opacity-40"><i class="mdi mdi-controller"></i>
                                {{item.provider.label }}</span>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>