<div class="mx-auto z-0 !w-full !max-w-[1150px] overflow-auto mt-5 bg-slate-800 rounded-lg">

    <div class="flex justify-between border-b border-slate-600">
        <div routerLink="/user/lottery" [queryParams]="{tab: 'result'}"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            [ngClass]="currentTabState === 'result' ? 'bg-purple-950' : ''">
            Hasil
        </div>
        <div routerLink="/user/lottery" [queryParams]="{tab: 'markets'}"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Pasaran
        </div>
        <div routerLink="/user/lottery" [queryParams]="{tab: 'dream-interpretation'}"
            class="text-center w-full hover:bg-purple-950/40 transition-all ease-in duration-200 rounded-lg cursor-pointer font-bold p-2"
            routerLinkActive="bg-purple-950">
            Buku Mimpi
        </div>
    </div>

    <!-- Togel -->
    <div data-pane="tabpane" class="block mx-auto" id="result">
        <div class="flex justify-between p-3">
            <div>
                <h2 class="border-l-4 border-purple-600 pl-3 uppercase font-bold">Hasil terakhir</h2>
            </div>
            <div class="flex gap-3 items-center ">
                <mat-slide-toggle (change)="switchUiTogel($event)" [checked]="togelGrid" />

                <div class="flex items-center gap-1 hover:text-purple-800 cursor-pointer transition-all duration-300 text-sm"
                    routerLink="/lottery/history" (click)="alert.show('Ongoing Statistik', 'danger')">
                    <p class="mb-2">Statistik </p>
                    <mat-icon class="material-symbols-outlined mb-0 text-[15px]">arrow_forward_ios</mat-icon>
                </div>
            </div>
        </div>

        <div class="p-3">
            <div class="bg-slate-900 p-3 rounded-lg flex items-center gap-2">
                <mat-icon class="material-symbols-outlined">search</mat-icon>
                <input type="text" class="input-custom-1 w-full h-8 text-sm" placeholder="Cari pasaran...">
            </div>
        </div>

        <div class="mt-1">

            @if (togelGrid) {
            <div class="container mx-auto p-4">
                <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-5 gap-4">
                    <!-- Loop through the results and display each result in a grid item -->
                    <div *ngFor="let result of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]"
                        class="bg-slate-700 rounded-lg">
                        <div class="text-center text-sm py-2 bg-slate-900 rounded-t-lg">Judul Misal Toto</div>
                        <div class="text-center p-3">
                            <p class="text-sm">2023-12-12</p>
                            <p class="text-3xl">{{ result }}928</p>
                        </div>
                    </div>
                </div>
            </div>
            }@else {
            <accordion-container>
                <accordion-item *ngFor="let item of [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20];">
                    <ng-template #header>
                        <div class="flex justify-between items-center w-full">
                            <div class="flex items-center gap-3 cursor-pointer w-full">
                                <div class="min-w-[45px]">
                                    <img width="45" class="rounded-lg"
                                        src="https://vxbrkq1luxtv.gpa2glsjhw.xyz/ajax/image/PRC/1841_agdraw_SYDNEY_LOTTO/getImage/10PRC_10MAC_LOGO%20SDY%20LOTTO.jpeg"
                                        alt="">
                                </div>
                                <div>
                                    <a href="https://sydneylotto.direct" target="_blank"
                                        class="font-bold md:text-[15px] text-sm underline mb-1 md:mb-0 text-nowrap">
                                        SYDNEY LOTTO
                                    </a>
                                    <app-togel-number class="md:hidden flex gap-1 mt-1" pClass="w-7 h-7" value="1878" />
                                </div>
                            </div>


                            <p class="font-bold hidden md:block">{{item}}</p>
                            <p class="font-bold w-full md:text-sm text-xs md:text-center text-end">Sabtu, 26/10/2024
                                14:05</p>
                            <div class="mr-3 md:mr-10">
                                <!-- desktop show it -->
                                <app-togel-number class="md:flex hidden gap-2 w-full" pClass="w-10 h-10" value="1878" />
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #content>
                        <div class="">
                            <!-- 1 -->
                            <div class="flex justify-between items-center py-3 border-b border-slate-700"
                                *ngFor="let item of [1,2,3,4,5]">
                                <div class="flex items-center gap-3 cursor-pointer w-full">
                                    <div class="min-w-[45px]">
                                        <!-- id for mobile -->
                                        <p class="font-bold md:hidden block">{{item}}</p>
                                    </div>
                                    <div>
                                        <app-togel-number class="md:hidden flex gap-1" pClass="h-7 w-7" value="1878" />
                                    </div>
                                </div>

                                <!-- id for desktop -->
                                <p class="font-bold hidden md:block">{{item}}</p>
                                <p class="font-bold w-full md:text-sm text-xs md:text-center text-end">Sabtu, 26/10/2024
                                    14:05</p>
                                <div class="md:mr-12">
                                    <!-- desktop show it -->
                                    <app-togel-number class="hidden md:flex gap-2 w-full" pClass="w-10 h-10"
                                        value="1878" />
                                </div>
                            </div>

                            <!-- 2 -->
                            <div class="flex justify-between items-center py-3 border-b border-slate-700">
                                <div class="flex md:justify-end items-center gap-3 cursor-pointer w-full">
                                    <div class="min-w-[45px]">
                                        <button mat-button class="text-white underline" routerLink="/lottery/history"
                                            [queryParams]="{market: 'TOTOMACAU_1600'}">Lihat Semua</button>
                                    </div>
                                </div>
                                <div class="md:mr-12 flex md:justify-end gap-2 w-full">
                                    <div class="text-[12px] md:text-sm text-nowrap text-orange-400">
                                        <p>Tutup: 13.30 WIB</p>
                                        <p class="flex items-center text-start"><mat-icon
                                                class="material-symbols-outlined text-sm">schedule</mat-icon> <span
                                                class="-ml-2">17h 8m</span></p>
                                    </div>
                                    <div class="flex justify-end">
                                        <button mat-flat-button
                                            class="rounded-lg bg-yellow-600 text-xs md:text-sm p-1 md:p-3">Pasang</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </ng-template>
                </accordion-item>
            </accordion-container>
            }

        </div>
    </div>

    <!-- Markets -->
    <div data-pane="tabpane" class="hidden mx-auto" id="markets">
        <div class="flex justify-between p-4">
            <div>
                <h2 class="border-l-4 border-purple-600 pl-3 uppercase font-bold">Pasaran</h2>
            </div>
        </div>

        <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 p-3">

            <div class="wrapper-market disabled">
                <div class="status"></div>
                <div class="flex gap-3 items-center">
                    <div class="w-20">
                        <img class="rounded-full"
                            src="https://vxbrkq1luxtv.gpa2glsjhw.xyz/balak4d/assets/img/l4d/hkliveday.png" alt="">
                    </div>
                    <div class="w-full">
                        <div class="border-b py-1">
                            <h1 class="font-bold uppercase !m-0">Hongkong Live Day Draw</h1>
                            <p class="text-sm font-bold m-0">Setiap Hari</p>
                        </div>
                        <div class="flex mt-2 justify-between">
                            <p class="text-nowrap text-xs m-0"><span class="font-bold">Tutup</span><span>27/10/2024
                                    19.45
                                    WIB</span></p>
                            <p class="flex text-yellow-400 m-0"> <mat-icon
                                    class="material-symbols-outlined text-sm -mt-[.1rem]">schedule</mat-icon> <span
                                    class="text-sm -ml-2">0h 57m</span> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper-market">
                <div class="status"></div>
                <div class="flex gap-3 items-center">
                    <div class="w-20">
                        <img class="rounded-full"
                            src="https://vxbrkq1luxtv.gpa2glsjhw.xyz/balak4d/assets/img/l4d/hkliveday.png" alt="">
                    </div>
                    <div class="w-full">
                        <div class="border-b py-1">
                            <h1 class="font-bold uppercase !m-0">Hongkong Live Day Draw</h1>
                            <p class="text-sm font-bold m-0">Setiap Hari</p>
                        </div>
                        <div class="flex mt-2 justify-between">
                            <p class="text-nowrap text-xs m-0"><span class="font-bold">Tutup</span><span>27/10/2024
                                    19.45
                                    WIB</span></p>
                            <p class="flex text-yellow-400 m-0"> <mat-icon
                                    class="material-symbols-outlined text-sm -mt-[.1rem]">schedule</mat-icon> <span
                                    class="text-sm -ml-2">0h 57m</span> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper-market">
                <div class="status"></div>
                <div class="flex gap-3 items-center">
                    <div class="w-20">
                        <img class="rounded-full"
                            src="https://vxbrkq1luxtv.gpa2glsjhw.xyz/balak4d/assets/img/l4d/hkliveday.png" alt="">
                    </div>
                    <div class="w-full">
                        <div class="border-b py-1">
                            <h1 class="font-bold uppercase !m-0">Hongkong Live Day Draw</h1>
                            <p class="text-sm font-bold m-0">Setiap Hari</p>
                        </div>
                        <div class="flex mt-2 justify-between">
                            <p class="text-nowrap text-xs m-0"><span class="font-bold">Tutup</span><span>27/10/2024
                                    19.45
                                    WIB</span></p>
                            <p class="flex text-yellow-400 m-0"> <mat-icon
                                    class="material-symbols-outlined text-sm -mt-[.1rem]">schedule</mat-icon> <span
                                    class="text-sm -ml-2">0h 57m</span> </p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="wrapper-market">
                <div class="status"></div>
                <div class="flex gap-3 items-center">
                    <div class="w-20">
                        <img class="rounded-full"
                            src="https://vxbrkq1luxtv.gpa2glsjhw.xyz/balak4d/assets/img/l4d/hkliveday.png" alt="">
                    </div>
                    <div class="w-full">
                        <div class="border-b py-1">
                            <h1 class="font-bold uppercase !m-0">Hongkong Live Day Draw</h1>
                            <p class="text-sm font-bold m-0">Setiap Hari</p>
                        </div>
                        <div class="flex mt-2 justify-between">
                            <p class="text-nowrap text-xs m-0"><span class="font-bold">Tutup</span><span>27/10/2024
                                    19.45
                                    WIB</span></p>
                            <p class="flex text-yellow-400 m-0"> <mat-icon
                                    class="material-symbols-outlined text-sm -mt-[.1rem]">schedule</mat-icon> <span
                                    class="text-sm -ml-2">0h 57m</span> </p>
                        </div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    <!-- End Of Markets -->

    <!-- Dream Interpretation -->
    <div data-pane="tabpane" class="hidden mx-auto" id="dream-interpretation">
        <div class="flex justify-between p-4">
            <div>
                <h2 class="border-l-4 border-purple-600 pl-3 uppercase font-bold">Buku Mimpi</h2>
            </div>
        </div>

        <div class="p-3">

            <!-- Filter -->
            <div class="bg-slate-900 rounded-lg p-3 flex flex-col md:flex-row gap-2 w-full items-center">
                <div class="flex gap-2 md:w-[29rem] w-full items-center">
                    <div class="w-full">
                        <select type="text" class="input-custom-1 w-full">
                            <option value="2d">2D</option>
                        </select>
                    </div>
                    <div class="w-full">
                        <select type="text" class="input-custom-1 w-full">
                            <option value="kata-kunci">Kata Kunci</option>
                        </select>
                    </div>
                </div>
                <div class="relative w-full">
                    <input type="search" name="search-text" class="input-custom-1 w-full"
                        placeholder="Masukan kata kunci">
                    <mat-icon class="material-symbols-outlined absolute right-3 top-3">search</mat-icon>
                </div>
                <button mat-button class="bg-yellow-600 rounded-lg text-white md:w-72 w-full">Cari arti
                    mimpi</button>
            </div>

            <!-- Table Dream -->
            <div class="overflow-x-auto mt-3 rounded-lg bg-gray-700">
                <table class="table-striped w-full text-xs text-left text-gray-700 border-none">
                    <thead class="bg-slate-900 text-gray-200">
                        <tr>
                            <th scope="col" class="px-2 py-3 text-center">No</th>
                            <th scope="col" class="px-6 py-3">Nama</th>
                            <th scope="col" class="px-6 py-3">Kota</th>
                        </tr>
                    </thead>
                    <tbody class="bg-slate-800">
                        <tr>
                            <td class="px-2 py-2 text-center">1</td>
                            <td class="px-4 py-2 text-wrap">Penyair - Tapir - Sempritan - Rembulan - Tanggalan - Kumbo
                                KARN0</td>
                            <td class="px-3 py-2">00 (97-48-64-98)</td>
                        </tr>
                        <tr>
                            <td class="px-2 py-2 text-center">2</td>
                            <td class="px-4 py-2 text-wrap">Penyair - Tapir - Sempritan - Rembulan - Tanggalan - Kumbo
                                KARN0</td>
                            <td class="px-3 py-2">00 (97-48-64-98)</td>
                        </tr>
                        <tr>
                            <td class="px-2 py-2 text-center">3</td>
                            <td class="px-4 py-2 text-wrap">Penyair - Tapir - Sempritan - Rembulan - Tanggalan - Kumbo
                                KARN0</td>
                            <td class="px-3 py-2">00 (97-48-64-98)</td>
                        </tr>
                        <tr>
                            <td class="px-2 py-2 text-center">4</td>
                            <td class="px-4 py-2 text-wrap">Penyair - Tapir - Sempritan - Rembulan - Tanggalan - Kumbo
                                KARN0</td>
                            <td class="px-3 py-2">00 (97-48-64-98)</td>
                        </tr>
                    </tbody>
                </table>
            </div>

        </div>
    </div>
    <!-- End Of Dream Interpretation -->
</div>